// Activated animation on scroll
(function($, window, document, undefined) {
  "use strict";
  var animationObject;

  function nvrAddAnimation() {
    animationObject.each(function(index, element) {
      var $currentElement = $(element),
        animationType = $currentElement.attr("kl_timeline_animation_type");

      if (nvrOnScreen($currentElement)) {
        $currentElement.addClass("animated " + animationType);
      }
    });
  }

  // takes jQuery(element) a.k.a. $('element')
  function nvrOnScreen(element) {
    // window bottom edge
    var windowBottomEdge = $(window).scrollTop() + $(window).height();

    // element top edge
    var elementTopEdge = element.offset().top;
    var offset = 150;

    // if element is between window's top and bottom edges
    return elementTopEdge + offset <= windowBottomEdge;
  }

  $(window).load(function() {
    animationObject = $("[kl_timeline_animation_type]");
    if (animationObject !== false) {
      nvrAddAnimation();
    }
  });

  $(window).on("scroll", function(e) {
    animationObject = $("[kl_timeline_animation_type]");
    if (animationObject !== false) {
      nvrAddAnimation();
    }
  });
})(jQuery, window, document);
