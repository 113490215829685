function side($) {
	var paire = true;
	var currentYear = 0;

	$('.kl_timeline_article').each(function() {
		if(paire) {
			if($(this).attr('kl_timeline_animation_type') == '') {
				$(this).attr('kl_timeline_animation_type', 'fadeInLeft');
			}
			$(this).addClass('kl_timeline_article_left');
			$(this).find('.kl_timeline_year').addClass('kl_timeline_year_left');
		} else {
			if($(this).attr('kl_timeline_animation_type') == '') {
				$(this).attr('kl_timeline_animation_type', 'fadeInRight');
			}
			$(this).addClass('kl_timeline_article_right');
			$(this).find('.kl_timeline_year').addClass('kl_timeline_year_right');
		}
		paire = !paire;
	});

	$('.kl_timeline_year').each(function() {

		// Check if element exist
		var year = $(this).find('> span').html();

		if (year == currentYear) {
			$(this).remove();
		} else {
			currentYear = year;
		}
	});
}
