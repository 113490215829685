jQuery(function($) {
	// Test if the infinite scroll div is in the page
	if ($(".kl_timeline_infinite_scroll").length == 0) {
		return;
	}

	var canBeLoaded = true

	$(window).live("scroll", function() {
		var data = {
			action: "kl_timeline_loadmore",
			query: $('.kl_timeline_infinite_scroll, .kl_timeline_loadmore').attr('data-query'),
			page: kl_timeline_loadmore_params.current_page
		};		

		if (isScrolledIntoView($(".kl_timeline_infinite_scroll")) && canBeLoaded == true) {

			$.ajax({
				url: kl_timeline_loadmore_params.ajaxurl,
				data: data,
				type: "POST",
				beforeSend: function(xhr) {
					canBeLoaded = false;
					$('.kl_timeline_infinite_scroll').append('<i class="fas fa-circle-notch fa-spin spinner-loadmore"></i>');
				},
				success: function(data) {
					
					if (data) {
						$('.kl_timeline_infinite_scroll').prev().after(data);
						$(".spinner-loadmore").remove();

						canBeLoaded = true;
						kl_timeline_loadmore_params.current_page++;
						if (
							kl_timeline_loadmore_params.current_page ==
							kl_timeline_loadmore_params.max_page
						) {
							$(".kl_timeline_infinite_scroll").remove();
						}
					} else {
						$(".kl_timeline_infinite_scroll").remove();
					}
					side($);
				}
			});
		}
	});

	function isScrolledIntoView(elem){
		if ($(".kl_timeline_infinite_scroll").length == 0) {
			return;
		}
	
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }
});
