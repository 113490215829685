jQuery(function($) {

  $('.kl_timeline_open_modal').click(function(e) {
    e.preventDefault();

    var modal = $('.kl_timeline_modal');

    modal.css('display', '');
    modal.attr('aria-hidden', false);
    modal.attr('aria-modal', true);

    modal.click(closeModal);

    $('.kl_timeline_modal_close').click(closeModal);

    $('.kl_timeline_modal_content').click(function(e) {
      e.stopPropagation();
    });
  });

  var closeModal = function(e) {
    e.preventDefault();
    var modal = $('.kl_timeline_modal');

    setTimeout(function() {
      modal.css('display', 'none');
    }, 500);
    modal.attr('aria-hidden', false);
    modal.attr('aria-modal', false);
  }

});