jQuery(function($) {

	// Test if the loadmore button is in the page
	if($('.kl_timeline_loadmore').length == 0) {
		return;
	}

	$('.kl_timeline_loadmore').click(function() {
		var button = $(this);

		var data = {
			'action' : 'kl_timeline_loadmore',
			'query'	 : $('.kl_timeline_loadmore, .kl_timeline_infinite_scroll').attr('data-query'),
			'page'	 : kl_timeline_loadmore_params.current_page,
		};

		$.ajax({
			url : kl_timeline_loadmore_params.ajaxurl,
			data: data,
			type: 'POST',
			beforeSend : function (xhr) {
				button.append('<i class="fas fa-circle-notch fa-spin spinner-loadmore"></i>');
			},
			success : function(data){
				if(data){
					button.prev().after(data);
					$('.spinner-loadmore').remove();
					kl_timeline_loadmore_params.current_page++;

					if(kl_timeline_loadmore_params.current_page == kl_timeline_loadmore_params.max_page) {
						button.remove();
					}
				} else {
					button.remove();
				}
				side($);
			}
		});
	});
});
